import { SVGProps, Ref, forwardRef } from 'react'

const SvgHealthCareDirective = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.72 3.22A.75.75 0 0 1 2.25 3h6a.75.75 0 0 1 .75.75v.75A1.5 1.5 0 0 0 10.5 6h9.75a.75.75 0 0 0 0-1.5H10.5v-.75A2.25 2.25 0 0 0 8.25 1.5h-6A2.25 2.25 0 0 0 0 3.75V19.5a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V8.25a.75.75 0 0 0-.75-.75h-18a.75.75 0 0 0-.75.75V19.5a1.5 1.5 0 0 1-3 0V3.75a.75.75 0 0 1 .22-.53ZM21 21H5.598A3 3 0 0 0 6 19.5V9h16.5v10.5A1.5 1.5 0 0 1 21 21Zm-7.5-10.5A1.5 1.5 0 0 0 12 12v.75h-.75a1.5 1.5 0 0 0-1.5 1.5v1.5a1.5 1.5 0 0 0 1.5 1.5H12V18a1.5 1.5 0 0 0 1.5 1.5H15a1.5 1.5 0 0 0 1.5-1.5v-.75h.75a1.5 1.5 0 0 0 1.5-1.5v-1.5a1.5 1.5 0 0 0-1.5-1.5h-.75V12a1.5 1.5 0 0 0-1.5-1.5h-1.5Zm0 1.5H15v1.5c0 .414.336.75.75.75h1.5v1.5h-1.5a.75.75 0 0 0-.75.75V18h-1.5v-1.5a.75.75 0 0 0-.75-.75h-1.5v-1.5h1.5a.75.75 0 0 0 .75-.75V12Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgHealthCareDirective)
export default ForwardRef
